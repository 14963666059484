import React, { Fragment } from "react";

import { useEffect, useState } from "react";
import "../styles/Main.scss";

import Footer from "../components/Footer";

import { useTranslation } from "react-i18next";
import LatestNews from "../components/LatestNews";
import EELUMediaVideos from "../components/EELUMediaVideos";
import EELUMemberOf from "../components/EELUMemberOf";
import Centers from "./../components/Centers";
import SpecializedCenters from "./../components/SpecializedCenters";
import ShortIcons from "../components/ShortIcons";
import Homepage from "../components/Homepage";
import AboutEELUHome from "../components/AboutEELUHome";
import Magazine from "../components/Magazine";
import FacebookMessenger from "../components/FBMessengerChat";
import Partner from "../components/Partner";
import Statystics from "../components/Statistics";
import Breadcrumbs from "../components/Breadcrumbs";
import Advertiser from "../components/Advertiser";
const Home = () => {
  const [t, i18n] = useTranslation();

  return (
    <Fragment>
      {/* <Advertiser /> */}
      {/* {clicked && <Overlay clicked={clicked} handlerFromParent={handlerFromParent} />} */}
      <Homepage />
      <br></br>
      {/* <FacebookMessenger /> */}
      <EELUMemberOf />
      <AboutEELUHome />
      <br />
      <br />
      <LatestNews />
      <EELUMediaVideos />
      <SpecializedCenters />
      <Centers />
      <Magazine />
      <ShortIcons />
      <br></br>
      <br></br>
      <Partner />

      <Statystics />
    </Fragment>
  );
};

export default Home;
