import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment } from "react";
import DefaultHeader from "../components/DefaultHeader";
import { useTranslation } from 'react-i18next';
import HeaderImg from "../pages/Imgs/SSU.png";
import { Col, Row, Container } from "react-bootstrap";



const SalesforceForm = () => {

    const [t, i18n] = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        studentId: '',
        email: '',
        phone: '',
        caseReason: 'طلب رصد تقدير " غير مكتمل " Incomplete',
        currentMajor: '',
        desiredMajor: '',
        currentCenter: '',
        transferReason: '',
        description: '',
        subject:'',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = document.createElement('form');
        form.action = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
        form.method = 'POST';

        const fields = {
            orgid: '00D4L000000i6vU',
            retURL: 'https://www.eelu.edu.eg/',
            name: formData.name,
            '00N4L00000A7ewg': formData.studentId,
            email: formData.email,
            phone: formData.phone,
            type: 'Request',
            recordType: '0124L000000gT64',
            '00N4L000003swUg': 'Other',
            '00N4L00000ASAKj': '1',
            '00N4L000003swUf': formData.caseReason,
            '00N4L00000AS0hq': formData.currentMajor,
            '00N4L00000AS0hv': formData.desiredMajor,
            '00N4L00000AS0i0': formData.currentCenter,
            '00N4L00000AS0iK': formData.transferReason,
            description: formData.description,
            subject: formData.subject,
            status: 'New',
        };

        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = fields[key];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
    };

    return (
        <Fragment>
            <DefaultHeader title={t('SSU')} backgroundImage={HeaderImg} />
            <div className="BodyDiv">
                <Container className="MainPageBodyContainer">
                    <div className="BodyTitle">
                        <Row>
                            <Col xs={12} md={12}>
                                <br />
                                <div style={{ color: 'rgb(17, 38, 113)', marginRight: "-3%" }}>
                                    <svg width="50" height="35" viewBox="0 0 215 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG Path data */}
                                    </svg>
                                    طلب رصد تقدير " غير مكتمل " Incomplete
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <h3 style={{ marginTop: "2%", color: "rgb(17, 38, 113)" }}></h3>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={4}>
                                <label htmlFor="name">أسم الطالب</label>
                                <input
                                    id="name"
                                    maxLength="80"
                                    name="name"
                                    size="20"
                                    type="text"
                                    className="form-control"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <label htmlFor="00N4L00000A7ewg">الرقم الجامعي</label>
                                <input
                                    id="00N4L00000A7ewg"
                                    maxLength="10"
                                    name="studentId"
                                    size="20"
                                    type="text"
                                    className="form-control"
                                    placeholder="XXXXXXX (يرجي ادخال الرقم الجامعي بدون -)"
                                    pattern="[0-9]{7}"
                                    required
                                    value={formData.studentId}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <label htmlFor="email">البريد الإلكتروني الجامعي</label>
                                <input
                                    id="email"
                                    maxLength="80"
                                    name="email"
                                    size="20"
                                    type="text"
                                    placeholder="xxxxxx@student.eelu.edu.eg"
                                    className="form-control"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <label htmlFor="phone">الموبايل</label>
                                <input
                                    id="phone"
                                    maxLength="11"
                                    name="phone"
                                    size="20"
                                    type="text"
                                    className="form-control"
                                    pattern="^[\u0660-\u0669\u06F0-\u06F90-9]{11}"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <label htmlFor="00N4L000003swUf">نوع الطلب</label>
                                <select
                                    id="00N4L000003swUf"
                                    name="caseReason"
                                    title="Case Reason custom"
                                    className="form-control"
                                    value={formData.caseReason}
                                    onChange={handleChange}
                                >
                                    <option value='طلب رصد تقدير " غير مكتمل " Incomplete'>طلب رصد تقدير " غير مكتمل " Incomplete</option>
                                </select>
                            </Col>
                            <Col xs={12} md={4}>
                                <label htmlFor="00N4L00000AS0i0">المركز الدراسي المقيد به</label>
                                <select
                                    id="00N4L00000AS0i0"
                                    name="currentCenter"
                                    className="form-control"
                                    title="Current Center"
                                    required
                                    value={formData.currentCenter}
                                    onChange={handleChange}
                                >
                                    <option value="">أختر المركز</option>
                                    <option value="Ain Shams">عين شمس</option>
                                    <option value="Alexandria">الإسكندرية</option>
                                    <option value="Alfayoum">الفيوم</option>
                                    <option value="Alsadat">السادات</option>
                                    <option value="Assuit">أسيوط</option>
                                    <option value="Aswan">أسوان</option>
                                    <option value="Beni Suef">بني سويف</option>
                                    <option value="Hurghada">الغردقة</option>
                                    <option value="Ismailia">الإسماعيلية</option>
                                    <option value="Menoufia">المنوفية</option>
                                    <option value="Qena">قنا</option>
                                    <option value="Sohag">سوهاج</option>
                                    <option value="Tanta">طنطا</option>
                                </select>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label htmlFor="subject">أسم المقرر</label>
                                <select
                                    className="form-control"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">أختر المقرر</option>
                                    <option value="">--------------------------------------------------------------------------- مقررات حاسبات -------------------------------------------------------------------------- -</option>

                                    <option value="1 Mathematics">1 Mathematics</option>
<option value="1 Programming Techniques">1 Programming Techniques</option>
<option value="Advanced Computer Networks">Advanced Computer Networks</option>
<option value="Advanced Machine Learning lab">Advanced Machine Learning lab</option>
<option value="Algorithms Analysis and Design">Algorithms Analysis and Design</option>
<option value="Algorithms and Data Structures">Algorithms and Data Structures</option>
<option value="Artificial Intelligence">Artificial Intelligence</option>
<option value="Artificial Intelligence for Cybersecurity">Artificial Intelligence for Cybersecurity</option>
<option value="Automata Models">Automata Models</option>
<option value="Bioinformatics Systems">Bioinformatics Systems</option>
<option value="Communication Technology">Communication Technology</option>
<option value="Computational mathematics for learning and data analysis">Computational mathematics for learning and data analysis</option>
<option value="Computer Graphics">Computer Graphics</option>
<option value="Computer Networks 1">Computer Networks 1</option>
<option value="Computer Networks 2">Computer Networks 2</option>
<option value="Computer Networks Technology">Computer Networks Technology</option>
<option value="Computer Organization">Computer Organization</option>
<option value="Computer Organization 2">Computer Organization 2</option>
<option value="Computer Vision">Computer Vision</option>
<option value="Database Systems">Database Systems</option>
<option value="Digital Signal Processing">Digital Signal Processing</option>
<option value="Discrete Mathematics">Discrete Mathematics</option>
<option value="Electronics">Electronics</option>
<option value="Embedded Systems">Embedded Systems</option>
<option value="English Language">English Language</option>
<option value="Entrepreneurship">Entrepreneurship</option>
<option value="Graduation Project 1">Graduation Project 1</option>
<option value="Human Computer Interaction">Human Computer Interaction</option>
<option value="Human Rights">Human Rights</option>
<option value="Image Processing">Image Processing</option>
<option value="Introduction to Computers">Introduction to Computers</option>
<option value="Introduction to Database systems">Introduction to Database systems</option>
<option value="Introduction to Information Technology">Introduction to Information Technology</option>
<option value="Introduction to Software Engineering">Introduction to Software Engineering</option>
<option value="Machine Learning Fundamentals">Machine Learning Fundamentals</option>
<option value="Mathematics - 0">Mathematics - 0</option>
<option value="Mathematics 1">Mathematics 1</option>
<option value="Mathematics 3">Mathematics 3</option>
<option value="Mobile and Sensor Networks">Mobile and Sensor Networks</option>
<option value="Modeling and Simulation">Modeling and Simulation</option>
<option value="Multimedia and Virtual Reality">Multimedia and Virtual Reality</option>
<option value="Network Routing and Switching">Network Routing and Switching</option>
<option value="Object Oriented Programming">Object Oriented Programming</option>
<option value="Operating Systems">Operating Systems</option>
<option value="Probability and Statistics-2">Probability and Statistics-2</option>
<option value="Programming Techniques 3">Programming Techniques 3</option>
<option value="Project 1">Project 1</option>
<option value="Report Writing and Presentation">Report Writing and Presentation</option>
<option value="Research Methods in Software Engineering">Research Methods in Software Engineering</option>
<option value="Selected Labs in Software Engineering">Selected Labs in Software Engineering</option>
<option value="Software Engineering 1">Software Engineering 1</option>
<option value="Software Engineering 2">Software Engineering 2</option>
<option value="Software Management">Software Management</option>
<option value="Software Requirements Engineering">Software Requirements Engineering</option>
<option value="Software Testing & Quality Assurance">Software Testing & Quality Assurance</option>
<option value="Systems and Operations Research">Systems and Operations Research</option>
<option value="Technical Report Writing">Technical Report Writing</option>
<option value="Three Dimensional Graphics">Three Dimensional Graphics</option>
<option value="Web Engineering 1">Web Engineering 1</option>
<option value="Web Engineering 2">Web Engineering 2</option>
<option value="Web Engineering 3">Web Engineering 3</option>

                                    <option value="">--------------------------------------------------------------------------- مقررات أدراة الاعمال --------------------------------------------------------------------------- </option>


<option value="Accounting and Auditing Professional Ethics">Accounting and Auditing Professional Ethics</option>
<option value="Advanced Financial Accounting">Advanced Financial Accounting</option>
<option value="Business Communications">Business Communications</option>
<option value="Business Environment and Ethics">Business Environment and Ethics</option>
<option value="Business Law">Business Law</option>
<option value="Consumer Behavior">Consumer Behavior</option>
<option value="Cost Accounting 1">Cost Accounting 1</option>
<option value="English Language 1">English Language 1</option>
<option value="English Language 2">English Language 2</option>
<option value="Entrepreneurial Organizations">Entrepreneurial Organizations</option>
<option value="Environmental Studies">Environmental Studies</option>
<option value="Financial Analysis">Financial Analysis</option>
<option value="Human Resource Management">Human Resource Management</option>
<option value="Human Rights">Human Rights</option>
<option value="Human Rights and Ethics">Human Rights and Ethics</option>
<option value="Intermediate Accounting (1)">Intermediate Accounting (1)</option>
<option value="International Business Management">International Business Management</option>
<option value="Introduction to Behavioral Sciences">Introduction to Behavioral Sciences</option>
<option value="Introduction to Biological science">Introduction to Biological science</option>
<option value="Introduction to Business">Introduction to Business</option>
<option value="Introduction to Business Informatics">Introduction to Business Informatics</option>
<option value="Introduction to Computers">Introduction to Computers</option>
<option value="Introduction to E Commerce">Introduction to E Commerce</option>
<option value="Introduction to Marketing">Introduction to Marketing</option>
<option value="Introduction to Operations Research">Introduction to Operations Research</option>
<option value="Knowledge Management">Knowledge Management</option>
<option value="Management Information Systems 1">Management Information Systems 1</option>
<option value="Managerial Accounting">Managerial Accounting</option>
<option value="Marketing Communications">Marketing Communications</option>
<option value="Marketing Research">Marketing Research</option>
<option value="Materials Management">Materials Management</option>
<option value="Microeconomics">Microeconomics</option>
<option value="Money and Banking">Money and Banking</option>
<option value="Organizational Change and Development">Organizational Change and Development</option>
<option value="Principles of Accounting 1">Principles of Accounting 1</option>
<option value="Principles of Investment">Principles of Investment</option>
<option value="Principles of Management">Principles of Management</option>
<option value="Production and Operations Management">Production and Operations Management</option>
<option value="Project Feasibility Study">Project Feasibility Study</option>
<option value="Quantitative Methods for Business">Quantitative Methods for Business</option>
<option value="Report Writing and Presentation">Report Writing and Presentation</option>
<option value="Strategic Management">Strategic Management</option>
<option value="Tax Accounting">Tax Accounting</option>
<option value="Technical Report Writing">Technical Report Writing</option>
<option value="Total Quality Management">Total Quality Management</option>
                                </select>
                               
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label htmlFor="description">ملاحظات</label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                ></textarea>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <button type="submit" className="btn btn-info"
                                    style={{

                                        backgroundColor: ' rgb(17 35 97)',
                                        justifyContent: 'center',
                                        padding: ' 8px 24px',
                                        borderRadius: '8px',
                                        color: ' #f4f5fc',
                                        fontSize: '20px',
                                        border: '0px',
                                        marginTop: '2%',
                                        marginBottom: "3%"
                                    }}
                                // disabled={}

                                >

                                    {t('Submit')}

                                </button>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </Fragment>
    );
}

export default SalesforceForm;
