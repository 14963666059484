import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../styles/componentsScss/Annoncement.scss";
import { BASE_URL } from "./apiConfig";
import axios from "axios";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";

function Annoncement() {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const [annonc, setAnnonc] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/news/studentNews/?page=${currentPage}`)
      .then((res) => {
        setAnnonc(res.data.data);
        setTotalPages(res.data.totalPages);
        // console.log(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [currentPage]);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  // console.log(pageNumbers);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    // if (currentPage < totalPages) {
    //     setCurrentPage(currentPage + 1);
    // }
    // console.log(currentPage);
    // console.log(totalPages)
  };

  return (
    <Fragment>
      <div
        style={{
          textAlign: urlPage === "en" ? "left" : "right",
          direction: urlPage === "en" ? "ltr" : "rtl",
        }}
        className={urlPage === "en" ? "annoncEn" : "annonc"}
      >
        {/* Right section with red background */}
        <button className="annoncemetnbtn">{t("Announcement")}</button>

        {annonc.slice(0, 1).map((annoncementt) => (
          <Link
            to={`/News/${annoncementt.params}`}
            state={annoncementt}
            key={annoncementt.params}
            className="titleannoncement"
          >
            {urlPage === "en" ? annoncementt.titleEn : annoncementt.title}
          </Link>
        ))}
        {/* Navigation arrows */}
        <div className="navigationsty" style={{}}>
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            style={{
              display: "flex",

              height: "32px",
              width: "32px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              border: "1px solid #E5E7EB",
              backgroundColor: currentPage === 1 ? "#D9D9D9" : "#09154E",
              transition: "background-color 0.2s",
              cursor: "pointer",
            }}
            // onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
            // onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
            aria-label="Previous"
          >
            {urlPage === "en" ? (
              <ChevronLeft
                style={{ height: "20px", width: "20px", color: "white" }}
              />
            ) : (
              <ChevronRight
                style={{ height: "20px", width: "20px", color: "white" }}
              />
            )}
          </button>
          <button
            style={{
              display: "flex",
              height: "32px",
              width: "32px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              border: "1px solid #E5E7EB",
              backgroundColor:
                currentPage === totalPages ? "#D9D9D9" : "#F6A62E",
              transition: "background-color 0.2s",
              cursor: "pointer",
            }}
            onClick={handleNextPage}
            disabled={totalPages === currentPage}
            // onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
            // onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
            aria-label="Next"
          >
            {urlPage === "en" ? (
              <ChevronRight
                style={{ height: "20px", width: "20px", color: "white" }}
              />
            ) : (
              <ChevronLeft
                style={{ height: "20px", width: "20px", color: "white" }}
              />
            )}
          </button>
        </div>
      </div>

      {/* <div
        style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
      >
        <button
          className={
            urlPage === "en" ? "buttonannoncementEn" : "buttonannoncement"
          }
        >
          {t("Announcement")}
        </button>
        <p
          style={{}}
          className={urlPage === "en" ? "pannonconEn" : "pannoncon"}
        >
          {annonc.slice(0, 1).map((annoncementt) => (
            <Link
              to={`/News/${annoncementt.params}`}
              state={annoncementt}
              key={annoncementt.params}
              style={{ textDecoration: "none" }}
            >
              <span className="titleannoncement">
                {urlPage === "en" ? annoncementt.titleEn : annoncementt.title}
              </span>
            </Link>
          ))}
        </p>
        <nav
          aria-label="Page navigation example"
          className={urlPage === "en" ? "pagnavEn" : "pagnav"}
          style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
        >
          <ul className="pagination annonpag" style={{ display: "flex" }}>
            <li className="page-item">
              <Button
                className="page-link"
                to="#"
                aria-label="Previous"
                style={{
                  transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                  marginRight: "15px",
                  backgroundColor: currentPage === 1 ? "#D9D9D9" : "#09154E",
                  color: "#ffffff",
                  borderRadius: "5px",
                  border: "none",
                  padding: "5%",
                }}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <span aria-hidden="true">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Button>
            </li>

            <li className="page-item">
              <Button
                className="page-link"
                to="#"
                aria-label="Next"
                style={{
                  transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                  marginRight: "15px",
                  backgroundColor:
                    currentPage === totalPages ? "#D9D9D9" : "#F6A62E",
                  color: "#ffffff",
                  borderRadius: "5px",
                  border: "none",
                  padding: "5%",
                }}
                onClick={handleNextPage}
                disabled={totalPages === currentPage}
              >
                <span aria-hidden="true">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Button>
            </li>
          </ul>
        </nav>
      </div> */}

      {/* <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ul
            style={{
              margin: "10px",
              marginTop: "-10%",
              padding: "0%",
              listStyleType: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            
            <li className="nav-item" style={{ display: "flex", gap: "10px" }}>
              <div className="annoncementbuttonContainer">
                <button type="" className="buttonannoncement">
                  {t("Announcement")}
                </button>
              </div>
              <div>
                {annonc.slice(0, 1).map((annoncementt) => (
                  <Link
                    to={`/News/${annoncementt.params}`}
                    state={annoncementt}
                    key={annoncementt.params}
                    style={{
                      marginTop: urlPage === "en" ? "1%" : "",
                      width: "75%",
                      textDecoration: "none",
                    }}
                  >
                    <p
                      className={
                        urlPage === "en"
                          ? "annoncementTitleEn"
                          : "annoncementTitle"
                      }
                    >
                      {urlPage === "en"
                        ? annoncementt.titleEn
                        : annoncementt.title}
                    </p>
                  </Link>
                ))}
              </div>
            </li>
          </ul>
        </div>
        <div style={{ display: "flex" }} className="">
          <nav
            aria-label="Page navigation example "
            className={urlPage === "en" ? "pagnavEn" : "pagnav"}
            style={{
              marginTop: urlPage === "en" ? "%" : "",
              marginRight: urlPage === "en" ? "%" : "",
            }}
          >
            <ul className="pagination ">
              <li className="page-item ">
                <Button
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  style={{
                    transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                    marginRight: "15px",
                    backgroundColor: currentPage === 1 ? "#D9D9D9" : "#09154E",
                    color: "#ffffff",
                    borderRadius: "5px",
                    border: "none",
                    padding: "5%",
                  }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <span aria-hidden="true">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Button>
              </li>

              <li className="page-item">
                <Button
                  className="page-link"
                  to="#"
                  aria-label="Next"
                  style={{
                    transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                    marginRight: "15px",
                    backgroundColor:
                      currentPage === totalPages ? "#D9D9D9" : "#F6A62E",
                    color: "#ffffff",
                    borderRadius: "5px",
                    border: "none",
                    padding: "5%",
                  }}
                  onClick={handleNextPage}
                  disabled={totalPages === currentPage}
                >
                  <span aria-hidden="true">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}

      {/* <button
        className={urlPage === "en" ? "annoncemetnbutonEN" : "annoncemetnbuton"}
        style={{ marginTop: urlPage === "en" ? "" : "" }}
      >
        {t("Announcement")}
      </button>
      {annonc.slice(0, 1).map((annoncementt) => (
        <Link
          to={`/News/${annoncementt.params}`}
          state={annoncementt}
          key={annoncementt.params}
          style={{
            marginTop: urlPage === "en" ? "1%" : "",
            width: "75%",
            textDecoration: "none",
          }}
        >
          <p
            className={
              urlPage === "en" ? "annoncementTitleEn" : "annoncementTitle"
            }
          >
            {urlPage === "en" ? annoncementt.titleEn : annoncementt.title}
          </p>
        </Link>
      ))}

      <nav
        aria-label="Page navigation example "
        className={urlPage === "en" ? "pagnavEn" : "pagnav"}
        style={{
          marginTop: urlPage === "en" ? "%" : "",
          marginRight: urlPage === "en" ? "%" : "",
        }}
      >
        <ul className="pagination ">
          <li className="page-item ">
            <Button
              className="page-link"
              to="#"
              aria-label="Previous"
              style={{
                transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                marginRight: "15px",
                backgroundColor: currentPage === 1 ? "#D9D9D9" : "#09154E",
                color: "#ffffff",
                borderRadius: "5px",
                border: "none",
                padding: "5%",
              }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <span aria-hidden="true">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </li>

          <li className="page-item">
            <Button
              className="page-link"
              to="#"
              aria-label="Next"
              style={{
                transform: urlPage === "en" ? "scaleX(1)" : "scaleX(-1)",
                marginRight: "15px",
                backgroundColor:
                  currentPage === totalPages ? "#D9D9D9" : "#F6A62E",
                color: "#ffffff",
                borderRadius: "5px",
                border: "none",
                padding: "5%",
              }}
              onClick={handleNextPage}
              disabled={totalPages === currentPage}
            >
              <span aria-hidden="true">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </li>
        </ul>
      </nav> */}
    </Fragment>
  );
}
export default Annoncement;
