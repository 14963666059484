import React, { Component } from "react";
import { Routes, Route, Router, Link } from "react-router-dom";
import Home from "../pages/Home";
import FacultyOfBusinessAdministrationAndCommercialStudies from "../pages/FacultyOfBusinessAdministrationAndCommercialStudies";
import FacultyOfComputersAndInformationTechnology from "../pages/FacultyOfComputersAndInformationTechnology";
import FacultyOfEducationalStudies from "../pages/FacultyOfEducationalStudies";
import SSUForm from "../pages/SSUForm";
import LearningCenter from "./../pages/LearningCenter";
import SubPageLearningCenter from "./../pages/SubPageLearningCenter";
import MonthlyMagazineMainPage from "./../pages/MonthlyMagazineMainPage";
import Scholarship from "../components/Scholarship";
import HowToApply from "./../pages/HowToApply";
import AboutEELU from "./../pages/AboutEELU";
import BoardOfTrustees from "../pages/BoardOf Trustees";
import SSUDone from "../pages/SSUDone";
import MediaCoverage from "../pages/MediaCoverage";
import Gallery from "../pages/Gallery";
import Navbar from "../components/Navbar";
import HeadWord from "../pages/HeadOfUniWord";
import { useTranslation } from "react-i18next";
import SubGallery from "../pages/SubGallery";
import BoardWord from "../pages/HeadOfBoardWord";
import EEluVision from "../pages/EEluVision";
import EEluMission from "../pages/EEluMission";
import DoctorsandResearches from "../pages/DoctorsandResearcher";
import Quality from "../pages/Quality";
import Fq from "../pages/FAQ";
import QuestionAndAnswer from "../pages/QuestionsAndAnswer";
import AboutUni from "../components/AboutUni";
import EELUHistory from "../pages/EELUHistory";
import Contact from "../pages/ContactUs";
import Trancefer from "../pages/TransferPolicy";
import StudySystemm from "../pages/StudySystem";
import AssExam from "../pages/AssementExam";
import LearningModel from "../pages/LearningModel";
import ECLUB from "../pages/ECLUB";
import InternationalRelation from "../pages/IntRelation";
import TICO from "../pages/Tico";
import Digital_Center from "../pages/DigitalCenter";
import ECPC from "../pages/ECPC";
import Member_Traning from "../pages/MemberTraning";
import E_Testing from "../pages/ETesting";
import ResearchProject from "../components/ResearchesProjects";
import Spedu from "../pages/SPEDUSproject";
import Estem from "../pages/ESTEMproject";
import E_labs from "../pages/E_LABS";
import Adip from "../pages/ADIPproject";
import Mes from "../pages/MSE";
import ScholoshipFirst from "../pages/ScholoshipFirst";
import ScholoshipReduceer from "../pages/ScholoshipReducing";
import ScholoshipSport from "../pages/ScholoshipSport";
import ScholoshipDisabilities from "../pages/ScholoshipDisabilities";
import ScholoshipFinancially from "../pages/ScholoshipFinancially";
import ScholoshipFraternity from "../pages/ScholoshipFraternity";
import ScholoshipMemberChildren from "../pages/ScholoshipMemberChildren";
import ScholoshipRules from "../pages/Scholoship_Rules";
import SpecialCenter from "../components/SpecialCenter";
import Recruitment from "../components/Recruitment";
import StaffHire from "../pages/StaffHire";
import StudentaffairHire from "../pages/StudentaffairHire";
import PostGraduate from "../components/PostGraduate";
import Library from "../components/Library";
import StudentActivity from "../components/StudentActivity";
import Statystics from "../components/Statistics";
import StudentActivityGallery from "../components/StudentActivityPhotos";
import SearchDisplay from "../components/SearchDisplayComponent";
import Breadcrumbs from "../components/Breadcrumbs";
import NewsBodySubPage from "../components/NewsBodySubPage";
import Advertiser from "../components/Advertiser";
import El_service from "../components/E_Service";
import Iso from "../pages/ISO";
import AllNewsComponent from "../components/AllNewsComponent";
import FCIQuality from "../pages/fciQuality";
import CLC from "../pages/CLC";
import CLCForm from "../pages/CLCForm";
import ClcCourse from "../pages/clcCourse";
import Sitemap from "../pages/Sitemap";
import TechnicalTiket from "../pages/TechnicalTicks";
import ScrollUpButton from "../components/ScrollUpButton";
import AcademicStaffForm from "../pages/AcademicStaffFrom";
import ArchiveJobs from "../pages/ArchiveJobs";
import { Helmet } from "react-helmet/es/Helmet";
import AboutFaculti from "../pages/AboutFaculties";
import StudySystemAll from "../pages/StudySystemAll";
import StudentForm from "../pages/StudentForm";
import MasterForm from "../pages/MasterForm";
import FacebookMessenger from "../components/FBMessengerChat";
import Policy from "../pages/Policy";
import NewsBodySubPage1 from "../components/NewsBodySubPage1";
import Leads from "../pages/Leads";
import ThankYou from "./../pages/ThankYou";
import GraduationParty from "../pages/GraduationParty";
import PresidentCompliant from "../pages/PresidentCompliant";
import PagenotFound from "../pages/PagenotFound";
import RecordMeeting from "../pages/RecornMeeting";
import Petitions from "../pages/Petitions";
import summerTraining from "./../pages/summerTraining";
import StaffHire1 from "../pages/StaffHire1";
import StaffHire2 from "../pages/StaffHire2";
import StaffHire3 from "../pages/StaffHire3";

function App() {
  const [t, i18n] = useTranslation();

  return (
    //
    <React.Fragment>
      <Helmet>
        <title>{t("slogan")}</title>
        <meta
          name="description"
          content="الجامعة المصرية للتعلم الإلكتروني الأهلية - مصر"
        />
        <meta
          name="keywords"
          content="الجامعة المصرية للتعلم الإلكتروني الأهلية - مصر"
        />
        <meta
          property="og:title"
          content="الجامعة المصرية للتعلم الإلكتروني الأهلية"
        />
        <meta name="referrer" content="origin-when-cross-origin" />

        {/* <meta property="og:description" content="Open Graph Description" /> */}
      </Helmet>
      {/* <TestnavBar /> */}

      <Navbar />
      {/* <Link to="/">Home</Link> */}
      <Breadcrumbs />

      <Routes>
        <Route path="/:lang?/" element={<Advertiser />} />
        <Route path="/:lang?/" element={<Home />} />
        <Route path="/:lang?/about-university" element={<AboutUni />} />
        <Route path="/:lang?/about-faculties" element={<AboutFaculti />} />
        <Route path="/:lang?/study-system-all" element={<StudySystemAll />} />
        <Route path="/:lang?/ssu-done" element={<SSUDone />} />
        <Route path="/:lang?/thank-you" element={<ThankYou />} />
        <Route path="/:lang?/not-found" element={<PagenotFound />} />
        <Route
          path="/:lang?/search-display/:word"
          element={<SearchDisplay />}
        />
        <Route path="/:lang?/media" element={<MediaCoverage />} />
        <Route path="/:lang?/board-of-trustees" element={<BoardOfTrustees />} />
        <Route
          path="/:lang?/faculty-staff"
          element={<DoctorsandResearches />}
        />
        <Route
          path="/:lang?/university-president-word"
          element={<HeadWord />}
        />
        <Route path="/:lang?/chairman-board-word" element={<BoardWord />} />
        <Route path="/:lang?/contact" element={<Contact />} />
        <Route path="/:lang?/recruitment" element={<Recruitment />} />
        {/* <Route path="/:lang?/archive-job" element={<ArchiveJobs />} /> */}
        <Route path="/:lang?/staff-hiring" element={<StaffHire />} />
        <Route path="/:lang?/staff-hiring1" element={<StaffHire1 />} />
        <Route path="/:lang?/staff-hiring2" element={<StaffHire2 />} />
        <Route path="/:lang?/staff-hiring3" element={<StaffHire3 />} />
        <Route path="/:lang?/incomplete-fall-2025" element={<MasterForm />} />
        <Route
          path="/:lang?/student-affair-hiring"
          element={<StudentaffairHire />}
        />
        <Route path="/:lang?/quality" element={<Quality />} />
        <Route
          path="/:lang?/about-faculties/faculty-of-computers-and-information-technology/fci-quality"
          element={<FCIQuality />}
        />
        <Route path="/:lang?/statistics" element={<Statystics />} />
        <Route
          path="/:lang?/faculty-of-educational-studies/postgraduate"
          element={<PostGraduate />}
        />
        <Route path="/:lang?/FAQ" element={<Fq />} />
        <Route path="/:lang?/e-club" element={<ECLUB />} />
        <Route path="/:lang?/specialized-centers/:params" element={<TICO />} />
        <Route path="/:lang?/digital-center" element={<Digital_Center />} />
        <Route path="/:lang?/ecpc" element={<ECPC />} />
        <Route path="/:lang?/e-testing" element={<E_Testing />} />
        <Route
          path="/:lang?/member-traning-center"
          element={<Member_Traning />}
        />
        <Route
          path="/:lang?/international-relations"
          element={<InternationalRelation />}
        />
        <Route path="/:lang?/eelu-learning-model" element={<LearningModel />} />
        <Route path="/:lang?/faq/:title" element={<QuestionAndAnswer />} />
        <Route
          path="/:lang?/media/student-activities"
          element={<StudentActivity />}
        />
        <Route path="/:lang?/media/sub-gallery/:params" element={<Gallery />} />
        <Route path="/:lang?/media/sub-gallery" element={<SubGallery />} />
        <Route
          path="/:lang?/media/student-activities/:params"
          element={<StudentActivityGallery />}
        />
        <Route
          path="/:lang?/about-university/history-of-eelu"
          element={<EELUHistory />}
        />
        <Route
          path="/:lang?/about-university/vision-of-eelu"
          element={<EEluVision />}
        />
        <Route
          path="/:lang?/about-university/mission-of-eelu"
          element={<EEluMission />}
        />
        <Route
          path="/:lang?/study-system-all/transfer"
          element={<Trancefer />}
        />
        <Route
          path="/:lang?/study-system-all/study-system"
          element={<StudySystemm />}
        />
        <Route
          path="/:lang?/study-system-all/assessment-examination-policy"
          element={<AssExam />}
        />
        <Route path="/:lang?/research-projects" element={<ResearchProject />} />
        <Route path="/:lang?/e-library" element={<Library />} />
        <Route path="/:lang?/specialized-centers" element={<SpecialCenter />} />
        <Route path="/:lang?/sp-edu" element={<Spedu />} />
        <Route path="/:lang?/e-stem" element={<Estem />} />
        <Route path="/:lang?/e-labs" element={<E_labs />} />
        <Route path="/:lang?/adip" element={<Adip />} />
        <Route path="/:lang?/Mes" element={<Mes />} />
        <Route path="/:lang?/e-Service" element={<El_service />} />
        {/* <Route path="/:lang?/ssu-major" element={<StudentForm />} /> */}
        {/* <Route path="/:lang?/summer-training" element={<MasterForm />} /> */}
        {/* <Route path="/:lang?/Scholarship-form" element={<Scolorshipforms />} /> */}
        {/* <Route path="/:lang?/Transfer-between-centres-form" element={<TransferCenters />} /> */}
        {/* <Route path="/:lang?/Contact-us-Leads" element={<Leads />} /> */}
        <Route path="/:lang?/graduation-party" element={<GraduationParty />} />
        <Route path="/:lang?/meeting" element={<RecordMeeting />} />
        {/* <Route path="/:lang?/ssu-appel" element={<Petitions />} /> */}
        {/* <Route path="/:lang?/it-master-appel" element={<MasterForm />} /> */}
        <Route
          path="/:lang?/study-system-all/scholarships"
          element={<Scholarship />}
        />
        <Route
          path="/:lang?/scholarships-rules"
          element={<ScholoshipRules />}
        />
        <Route
          path="/:lang?/scholarships-first"
          element={<ScholoshipFirst />}
        />
        <Route
          path="/:lang?/scholarships-Reducer"
          element={<ScholoshipReduceer />}
        />
        <Route
          path="/:lang?/scholarships-sport"
          element={<ScholoshipSport />}
        />
        <Route
          path="/:lang?/scholarships-disability"
          element={<ScholoshipDisabilities />}
        />
        <Route
          path="/:lang?/scholarships-financially"
          element={<ScholoshipFinancially />}
        />
        <Route
          path="/:lang?/scholarships-fraternity"
          element={<ScholoshipFraternity />}
        />
        <Route
          path="/:lang?/scholarships-member-children"
          element={<ScholoshipMemberChildren />}
        />
        <Route
          path="/:lang?/about-faculties/faculty-of-educational-studies/:subPage?"
          element={<FacultyOfEducationalStudies />}
        />
        <Route
          path="/:lang?/about-faculties/faculty-of-business-administration-and-commercial-studies/:sub-page?"
          element={<FacultyOfBusinessAdministrationAndCommercialStudies />}
        />
        <Route
          path="/:lang?/about-faculties/faculty-of-computers-and-information-technology/:sub-page?"
          element={<FacultyOfComputersAndInformationTechnology />}
        />
        <Route path="/:lang?/ssu-form/:type?" element={<SSUForm />} />
        <Route path="/:lang?/clc/:params/clcform" element={<CLCForm />} />
        <Route path="/:lang?/iso" element={<Iso />} />
        <Route path="/:lang?/News" element={<AllNewsComponent />} />
        <Route path="/:lang?/Learning-Center" element={<LearningCenter />} />
        <Route
          path="/:lang?/Learning-Center/:center?"
          element={<SubPageLearningCenter />}
        />
        <Route
          path="/:lang?/monthly-magazine"
          element={<MonthlyMagazineMainPage />}
        />
        <Route
          path="/:lang?/study-system-all/how-to-apply"
          element={<HowToApply />}
        />
        <Route path="/:lang?/clc" element={<CLC />} />
        <Route path="/:lang?/clc/:params" element={<ClcCourse />} />
        <Route path="/:lang?/News/:params" element={<NewsBodySubPage />} />
        <Route
          path="/:lang?/News/Best-Mobile-Image-Competition"
          element={<NewsBodySubPage1 />}
        />
        <Route path="/:lang?/about-eelu/:title?" element={<AboutEELU />} />
        <Route path="/:lang?/site-map" element={<Sitemap />} />

        <Route
          path="/:lang?/technical-tiket-form"
          element={<TechnicalTiket />}
        />
        <Route
          path="/:lang?/employee-complaints-and-suggestions"
          element={<PresidentCompliant />}
        />
        <Route
          path="/:lang?/academic-staff-form"
          element={<AcademicStaffForm />}
        />
        <Route path="/:lang?/privacy-policy" element={<Policy />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
      <ScrollUpButton />
      <FacebookMessenger />
    </React.Fragment>
  );
}

export default App;
